import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { ReactNode } from 'react';
import defaultFlags from './defaultFlags';

const initializeLaunchDarkly = async (): Promise<
  ({ children }: { children: ReactNode }) => JSX.Element
> =>
  asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_API_KEY,
    options: { bootstrap: defaultFlags },
  });

export default initializeLaunchDarkly;
