const isCypress: boolean = window.Cypress !== undefined;

enum ENV {
  PROD = 'production',
  DEV = 'development',
  TEST = 'test',
}

enum API_STATE {
  IDLE = 'idle', // No API call or error
  LOADING = 'loading', // Currently requesting
  ERROR = 'error', // Attempting request produced an error
}

enum REACT_APP_ENVIRONMENT {
  DEV = 'dev',
  QA = 'qa',
  PROD_PILOT = 'prod-pilot',
  PROD = 'prod',
}

const { QA, PROD, PROD_PILOT } = REACT_APP_ENVIRONMENT;

const getServerURL = (env: string) => {
  if (env === PROD || env === PROD_PILOT) {
    return 'https://delivery.grpc.my.chick-fil-a.com/';
  } else if (env === QA) {
    return 'https://delivery.grpc.qa.crndev.chick-fil-a.com';
  } else {
    return 'https://delivery.grpc.dev.crndev.chick-fil-a.com';
  }
};

const Constants = {
  REDUX_STORE_VERSION: 1,
  IS_CYPRESS_TEST: isCypress,
  BUGSNAG_ENABLED: process.env.NODE_ENV !== ENV.TEST && !isCypress,
  LAUNCH_DARKLY_ENABLED: process.env.NODE_ENV !== ENV.TEST && !isCypress,
  MPARTICLE_ENABLED: process.env.NODE_ENV !== ENV.TEST && !isCypress,
  ENV,
  REACT_APP_ENVIRONMENT,
  API_STATE,
  BASE_API_URL: getServerURL(process.env.REACT_APP_ENVIRONMENT),
};

export default Constants;
