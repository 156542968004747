import { LDFlagSet } from 'launchdarkly-js-sdk-common';

const defaultFlags: LDFlagSet = {
  groupOrderActions: false,
  createABatch: false,
  recoverOrder: false,
  reassignOrder: false,
  markOrderUndeliverable: false,
  manualBatching: false,
  spotlightLink: '',
  developerOnlyFeatures: false,
  stagingScreen: false,
  testOrders: false,
  notifications: false,
  cancelOrder: false,
  driverAlerts: false,
  assignToDriver: false,
  failover: false,
  erroredOrder: false,
  etaAnalytics: false,
  associateDrivers: false,
  manualCatering: false,
};

export default defaultFlags;
